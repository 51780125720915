import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import pic04 from '../images/pic04.jpg'
class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>We Are Creature started with a couple of friends from church just enjoying writing songs together and decided to start recording them.</p>

          <p>We love God and want others to love Him more deeply too and so music seemed to be the way we could share that. </p>
          <h3>Why are we called ‘We Are Creature’?</h3>
          <p>As humans we can so easily forget our place in creation. We tend to take things into our own hands and basically become gods in our own worlds. We put ourselves, our desires and our thoughts up on the throne of our hearts.</p>
           
          <p>We wanted a name that would keep bringing us back to the humble state we always need reminding to be in, as created beings of the God of the universe. Created to bring His name glory, not our own.</p>
          <p>So in all our songs we aim to point to God always. This is the purpose of our music. And in doing this, we pray to nourish your hearts and minds with truth so that you would come to know your creator more fully.
In all you do, we encourage you to bring glory to God.</p>
<p>Grace, peace and love to you all. <br />
~We Are Creature 🙏🏼</p>

          {close}
        </article>

        <article
          id="media"
          className={`${this.props.article === 'media' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Media</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <ul className="icons icons-media">
            <li>
              <a href="https://m.facebook.com/wearecreature" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>

            <li>
              <a href="https://instagram.com/wearecreature_music?igshid=16p4ob0os2oix" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>

            <li>
              <a
                href="https://open.spotify.com/artist/5RGtMV6HIOCksKsbGhZexw?si=y4jmUPR-T2q3CWTEiWK_wg"
                className="icon fa-spotify"
              >
                <span className="label">Spotify</span>
              </a>
            </li>
            
            
            <li>
              <a
                href="https://music.apple.com/au/artist/we-are-creature/1533111731"
                className="icon fa-apple"
              >
                <span className="label">Apple</span>
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UCQHoWVNUCijWcgT0kJRIiKQ"
                className="icon fa-youtube"
              >
                <span className="label">youtube</span>
              </a>
            </li>
          </ul>


          {close}
        </article>

        <article
          id="lyrics"
          className={`${this.props.article === 'lyrics' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Lyrics</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <h3>REDEEMED</h3>
          <p>Once was a dead man walking<br />
          Chased whatever pleased me<br />
          Even on my best days<br />
          Satisfaction was fleeting</p>

          <p>Then you called my name<br />
          Put in me a new heart<br />
          Opened up my eyes<br />
          And made me see</p>

          <p>You’ve erased my sin<br />
          Paid the price for me<br />
          No longer a slave<br />
          I’m redeemed <br />
          Your mercy shown<br />
          Your grace received<br />
          Now I am in Christ <br />
          I am free<br />
          I am free</p>

          <p>Now my chains have fallen<br />
          Not bound to condemnation <br />
          Called to a life that’s greater<br />
          In Christ a new creation</p>

          <p>Following your way<br />
          Living for your glory<br />
          Trusting in your grace<br />
          Walking by the Spirit now in me</p>

          <p>You’ve erased my sin<br />
          Paid the price for me<br />
          No longer a slave<br />
          I’m redeemed <br />
          Your mercy shown<br />
          Your grace received<br />
          Now I am in Christ <br />
          I am free<br />
          I am free</p>

          <p>From the ashes <br />
          You bring beauty<br />
          By your blood <br />
          You have renewed me<br />
          Death defeated<br />
          You rose in victory<br />
          So we praise your name</p>

          <p>You’ve erased my sin<br />
          Paid the price for me<br />
          No longer a slave<br />
          I’m redeemed <br />
          Your mercy shown<br />
          Your grace received<br />
          Now I am in Christ <br />
          I am free<br />
          I am free</p>

          <h3>GOOD &amp; TRUE</h3>
          <p>Look to the heavens<br />
          The work of Your hands<br />
          The whole earth it blesses<br />
          Your holy name<br />
          Let us be glad<br />
          offer our praise<br />
          For You are worthy<br />
          The Ancient of days</p>

          <p>You gave breath to these lungs<br />
          Life to these bones<br />
          all that we have<br />
          Is from you alone<br />
          All creation sings only of you<br />
          Your glorious splendour<br />
          God, good and true</p>

          <p>Giver of life<br />
          Oh Sovereign God<br />
          Light in the darkness<br />
          Hope of all<br />
          Rock of salvation<br />
          Our righteousness<br />
          Love undeserving<br />
          His gift of grace</p>

          <p>You gave breath to these lungs<br />
          You gave life to these bones<br />
          All that I have<br />
          Is from you alone<br />
          All Creation sings<br />
          Only of you<br />
          Your glorious splendour<br />
          God, good and true</p>

          <p>So we will lift our voice<br />
          And we will praise your name<br />
          Let us proclaim<br />
          Our God is great</p>

          <p>You gave breath to these lungs<br />
          You gave life to these bones<br />
          All that I have<br />
          Is from you alone<br />
          All Creation sings<br />
          Only of you<br />
          Your glorious splendour<br />
          God, good and true</p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <span className="image main">
            <img src={pic04} alt="" />
          </span>
          <p>Email: <a href="mailto:wearecreaturemusic@gmail.com">wearecreaturemusic@gmail.com</a></p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
