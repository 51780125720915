import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; We are creature.</p>
        <br />
        <ul className="icons">
            <li>
              <a href="https://m.facebook.com/wearecreature" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>

            <li>
              <a href="https://instagram.com/wearecreature_music?igshid=16p4ob0os2oix" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>

            <li>
              <a
                href="https://open.spotify.com/artist/5RGtMV6HIOCksKsbGhZexw?si=y4jmUPR-T2q3CWTEiWK_wg"
                className="icon fa-spotify"
              >
                <span className="label">Spotify</span>
              </a>
            </li>
            
            
            <li>
              <a
                href="https://music.apple.com/au/artist/we-are-creature/1533111731"
                className="icon fa-apple"
              >
                <span className="label">Apple</span>
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UCQHoWVNUCijWcgT0kJRIiKQ"
                className="icon fa-youtube"
              >
                <span className="label">youtube</span>
              </a>
            </li>
          </ul>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
