import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.png'
import logox2 from '../images/logo2.png'

import textLogo from '../images/text.png'
import textLogox2 from '../images/textx2.png'



const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img src={logox2} alt="" srcSet={`${logo} 1x, ${logox2} 2x`} />
    </div>
    <div className="content">
      <div className="inner">
        <img src={textLogox2} alt="WE ARE CREATURE" className="text-logo" srcSet={`${textLogo} 1x, ${textLogox2} 2x`} />
        <p>
          Created to bring God glory:<br />
          “Mans chief end is to glorify God,<br className="mobile-br" />
          and enjoy Him forever.”<br/>
          ‘Make a joyful noise to the Lord,<br className="mobile-br" /> 
          all the earth!’ Ps 98:4

        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('media')
            }}
          >
            Media
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('lyrics')
            }}
          >
            Lyrics
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
